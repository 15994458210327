<template>
    <div>
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="userData === undefined">
            <h4 class="alert-heading">
                Error al obtener la informacion del empleado
            </h4>
            <div class="alert-body">
                No se encontro ningun empleado con el id especificado.
                <b-link
                    class="alert-link"
                    :to="{ name: 'catalogos-empleados' }"
                >
                    User List
                </b-link>
                for other users.
            </div>
        </b-alert>

        <template v-if="userData">
            <b-row>
                <!-- aqui va estar todo el show para el registro -->
                <b-card no-body class="card">
                    <!-- Header -->
                    <b-card-body class="card-body">
                        <div class="d-flex justify-content-between p-0">
                            <div class="d-flex">
                                Empleado:
                                <h5 class="text-primary mr-2">
                                    {{
                                        this.capitalize(
                                            userData.data.nombre_completo
                                        )
                                    }}
                                </h5>
                                | Número de Empleado:
                                <h5 class="text-primary">
                                    {{ userData.data.numero_empleado }}
                                </h5>
                            </div>
                            <div class="d-flex">
                                Huellas capturadas:
                                <h5 class="text-primary">
                                    {{ getHuellasCapturadas() }}
                                </h5>
                            </div>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <span
                                class="title font-weight-bold"
                                style="width: 15% !important"
                            >
                                <feather-icon
                                    icon="SpeakerIcon"
                                    class=""
                                    size="16"
                                />
                                Lector: <span v-if="lector != null">✅</span
                                ><span v-if="lector == null">⚠️</span>
                            </span>
                            <div
                                style="width: 85% !important"
                                v-if="lectores == null"
                            >
                                <span class="text-danger fw-light">
                                    No se detectó ningun lector de huellas</span
                                >
                            </div>
                            <div
                                style="width: 85% !important"
                                v-if="lectores != null"
                            >
                                <b-form-select
                                    v-model="lector"
                                    :options="lectores"
                                    @change="seleccionar_lector($event)"
                                ></b-form-select>
                            </div>
                        </div>
                        <div class="mt-2 relative">
                            <img
                                :src="this.manos"
                                alt=""
                                class="p-1 img-thumbnail"
                                width="750px"
                            />
                            <!-- mano izquireda -->
                            <button
                                class="btn"
                                :class="
                                    this.userData.data.indexfinger != null
                                        ? 'btn-success'
                                        : this.capturando &&
                                          this.dedo_seleccionado == 1
                                        ? 'btn-warning'
                                        : 'btn-secondary'
                                "
                                style="
                                    position: absolute;
                                    top: 148px;
                                    left: 255px;
                                    padding: 13px 18px;
                                    border-radius: 60px;
                                "
                                @click="iniciar_captura(1)"
                            >
                                1
                            </button>
                            <!-- <button class="btn btn-secondary" style="position:absolute; top:112px; left:170px; padding: 13px 18px; border-radius: 60px;">2</button>
            <button class="btn btn-secondary" style="position:absolute; top:142px; left:85px; padding: 13px 18px; border-radius: 60px;">3</button>
            <button class="btn btn-secondary" style="position:absolute; top:210px; left:35px; padding: 13px 18px; border-radius: 60px;">4</button>
            <button class="btn btn-secondary" style="position:absolute; top:285px; left:335px; padding: 13px 18px; border-radius: 60px;">5</button> -->
                            <!-- mano derecha -->
                            <!-- <button class="btn btn-secondary" style="position:absolute; top:285px; right:325px; padding: 13px 18px; border-radius: 60px;">6</button>
            <button class="btn btn-secondary" style="position:absolute; top:215px; right:40px; padding: 13px 18px; border-radius: 60px;">7</button>
            <button class="btn btn-secondary" style="position:absolute; top:152px; right:85px; padding: 13px 18px; border-radius: 60px;">8</button>
            <button class="btn btn-secondary" style="position:absolute; top:125px; right:140px; padding: 13px 18px; border-radius: 60px;">9</button> -->
                            <button
                                class="btn"
                                :class="
                                    this.userData.data.indexfinger2 != null
                                        ? 'btn-success'
                                        : this.capturando &&
                                          this.dedo_seleccionado == 2
                                        ? 'btn-warning'
                                        : 'btn-secondary'
                                "
                                style="
                                    position: absolute;
                                    top: 158px;
                                    right: 225px;
                                    padding: 13px 18px;
                                    border-radius: 60px;
                                "
                                @click="iniciar_captura(2)"
                            >
                                2
                            </button>
                        </div>
                    </b-card-body>
                </b-card>
                <b-col
                    cols="12"
                    md="4"
                    xl="3"
                    class="invoice-actions mt-md-0 mt-2 text-center"
                >
                    <b-card>
                        <div class="text-justify" v-if="!capturando">
                            <p>
                                <b>Instrucciones:</b> <br />Seleccione un dedo
                                haciendo click en el boton gris correspondiente
                                para empezar a capturar la huella. <br /><b
                                    >*Nota:</b
                                >
                                Los el boton verde indica que ya tiene capturada
                                una huella en ese dedo.
                            </p>
                        </div>
                        <div
                            class="text-center"
                            v-if="capturando || this.dedo_samples.length == 4"
                        >
                            <img
                                :src="this.huella_dact_img"
                                alt=""
                                width="200px"
                                class="p-1 img-thumbnail img-rounded"
                                id="img_huella"
                                name="img_huella"
                            />
                            <h6
                                class="text-success mt-1"
                                :hidden="this.dedo_samples.length == 4"
                            >
                                Presione su dedo en el lector 4 veces ...
                            </h6>
                            <h3 :hidden="this.dedo_samples.length == 4">
                                {{ 4 - this.dedo_samples.length }} Restantes
                            </h3>
                        </div>
                        <!--   <div class="text-center" v-if="this.dedo_samples.length == 4">
            <img :src="this.huella_leida" alt="" width="200px" class="p-1 img-thumbnail img-rounded">
            <h6 class="text-success mt-1">Huella leida correctamente</h6>
          </div> -->
                        <div
                            class="text-center mt-1"
                            v-if="this.dedo_samples.length == 4"
                        >
                            <button
                                class="btn btn-success rounded-pill"
                                @click="guardar_huella()"
                            >
                                <i class="bi bi-save"></i> Guardar Huella
                            </button>
                        </div>
                    </b-card>
                    <a
                        rel="stylesheet"
                        href="https://drive.google.com/file/d/1lh_xvsPjNmUCTiaNzPl5qAiY6rABXcs4/view?usp=drive_link"
                        download
                        class="small"
                    >
                        <i class="bi bi-download"></i> Descargar Controlador del
                        Lector</a
                    >

                    <pre class="mt-2" :hidden="true">
            {{ this.dedo_samples }} 
          </pre
                    >
                </b-col>
            </b-row>
            <!-- <div class="text-center alert small alert-success" :class="this.lector_status == 'Dispositivo conectado' ? 'alert-success' : 'alert-danger'"> 
          {{ this.lector_status }}
      </div> -->
            <!-- <pre>
          {{ userData }}
      </pre> -->
        </template>
    </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    VBToggle,
    BTable,
    BPagination,
    BMedia,
    BFormSelect,
    BAlert,
    BLink,
    BAvatar,
} from "bootstrap-vue";
import userStoreModule from "../empleadoStoreModule";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import axios from "@axios";
import moment from "moment";
import huella_img from "../../../../assets/images/lector.png";
import manos from "../../../../assets/images/manos.png";
import huella_leida from "../../../../assets/images/huella_capturada.png";

export default {
    components: {
        ToastificationContent,
        manos,
        BRow,
        BCol,
        BAlert,
        BLink,
        BCard,
        BCardBody,
        BButton,
        BCardText,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormTextarea,
        BFormCheckbox,
        BPopover,
        VBToggle,
        BTable,
        BPagination,
        BMedia,
        BFormSelect,
        BAvatar,
        // Local Components
    },
    setup() {
        const userData = ref(null);

        const USER_APP_STORE_MODULE_NAME = "app-user";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
            store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME))
                store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        store
            .dispatch("app-user/fetchUser", {
                id: router.currentRoute.params.id,
            })
            .then((response) => {
                userData.value = response.data;
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    userData.value = undefined;
                }
            });

        return {
            userData,
        };
    },
    async mounted() {
        let _instance = this;
        this.sdk.onDeviceConnected = function (e) {
            // Detects if the device is connected for which acquisition started
            //showMessage("Scan Appropriate Finger on the Reader", "success");
            console.log("Dispositivo conectado");
            _instance.lector_status = "Dispositivo conectado";
            _instance.cargarLectores();
        };
        this.sdk.onDeviceDisconnected = function (e) {
            // Detects if device gets disconnected - provides deviceUid of disconnected device
            //showMessage("Device is Disconnected. Please Connect Back");
            console.log("Dispositivo desconectado");
            _instance.lector_status = "Dispositivo desconectado";
            _instance.cargarLectores();
        };
        this.sdk.onCommunicationFailed = function (e) {
            // Detects if there is a failure in communicating with U.R.U web SDK
            //showMessage("Communication Failed. Please Reconnect Device")
            console.log("Communication Failed. Please Reconnect Device");
            _instance.lector_status =
                "Communication Failed. Please Reconnect Device";
        };
        this.sdk.onSamplesAcquired = function (s) {
            // Sample acquired event triggers this function
            //console.warn(s);
            //let img = document.getElementById("img_huella");
            //img.src = "data:image/png;base64," + finger;
            //_instance.verifySample(s);
            //alert(s.samples[0]);
            _instance.storeSample(s);
        };
        this.sdk.onQualityReported = function (e) {
            // Quality of sample acquired - Function triggered on every sample acquired
            //document.getElementById("qualityInputBox").value = Fingerprint.QualityCode[(e.quality)];
        };
        await this.cargarLectores();
    },
    data() {
        return {
            manos: manos,
            verify_result: null,
            huella_dact_img: huella_img,
            sdk: new Fingerprint.WebApi(),
            //sdk_test : this.FingerprintSdkTest(),
            operationToRestart: null,
            acquisitionStarted: false,
            //sdk : null,
            capturando: false,
            lectores: [],
            lector: null,
            huella: null,
            lector_status: null,
            lector_calidad: null,
            dedo_seleccionado: null,
            dedo_samples: [],
            huella_leida: huella_leida,
        };
    },
    methods: {
        seleccionar_lector(event) {
            this.acquisitionStarted = false;
            this.detener_captura();
            this.iniciar_captura_lector();
        },
        iniciar_captura_lector() {
            if (this.acquisitionStarted)
                // Monitoring if already started capturing
                return;
            let _instance = this;
            //showMessage("");

            this.lector_status =
                "Iniciando captura en el lector " + this.lector;
            console.log("se inicio captura en el lector" + this.lector);
            this.operationToRestart = this.startCapture;
            this.sdk
                .startAcquisition(
                    Fingerprint.SampleFormat.Intermediate,
                    this.lector
                )
                .then(
                    function () {
                        _instance.acquisitionStarted = true;

                        //Disabling start once started
                        //disableEnableStartStop();
                    },
                    function (error) {
                        //showMessage(error.message);
                        alert(error.message);
                    }
                );
        },
        detener_captura() {
            if (!this.acquisitionStarted)
                //Monitor if already stopped capturing
                return;
            let _instance = this;
            //showMessage("");
            this.lector_status =
                "se detuvo captura en el lector " + this.lector;
            console.log("se detuvo captura en el lector " + this.lector);
            this.sdk.stopAcquisition().then(
                function () {
                    _instance.acquisitionStarted = false;

                    //Disabling stop once stopped
                    //disableEnableStartStop();
                },
                function (error) {
                    //showMessage(error.message);
                    alert(error.message);
                }
            );
        },
        storeSample(sample) {
            let samples = JSON.parse(sample.samples);
            console.log(samples[0]);
            this.dedo_samples.push(samples[0].Data);
            if (this.dedo_samples.length == 4) {
                this.capturando = false;
                this.detener_captura();
            }
        },
        verifySample(sample) {
            let samples = JSON.parse(sample.samples);
            console.log(samples[0]);
            //let finger = Fingerprint.b64UrlTo64(samples[0]);
            //this.huella_dact_img = "data:image/png;base64," + finger;
            //call axios service
            this.huella = samples[0].Data;
        },
        readyForIdentify() {
            return this.lector !== null;
        },
        async cargarLectores() {
            this.lectores = [];
            let rd = this.sdk.enumerateDevices();
            rd.then((readers) => {
                if (readers.length > 0) {
                    //this.lectores = availableReaders;
                    readers.forEach((reader) => {
                        this.lectores.push(reader);
                    });
                    this.lector = this.lectores[0];
                    //this.myReader.reader.startCapture();
                    //this.iniciar_captura();
                    //this.huella_dact_img = huella_img;
                } else {
                    //alert("Please Connect the Fingerprint Reader")
                    this.lector = null;
                }
            }).catch((error) => {
                console.log(error);
                this.lector = null;
            });
        },
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        getHuellasCapturadas() {
            let huellas = 0;
            if (this.userData.data.indexfinger != null) {
                huellas++;
            }
            if (this.userData.data.indexfinger2 != null) {
                huellas++;
            }
            return huellas;
        },
        iniciar_captura(dedo) {
            this.dedo_samples = [];
            this.capturando = true;
            this.dedo_seleccionado = dedo;
            this.iniciar_captura_lector();
        },
        guardar_huella() {
            let payload = {
                id: this.userData.data.id,
                index_finger: this.dedo_samples,
                dedo: this.dedo_seleccionado,
            };

            axios
                .post("/api/dragon/checador/registrarhuella", payload)
                .then((response) => {
                    this.verify_result = response.data.data;
                    if (response.status == 201) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                variant: "success",
                                title: "Huella registrada correctamente",
                                icon: "CheckSquareIcon",
                            },
                        });
                        this.userData.data = response.data.data;
                    } else if (response.status == 203) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                variant: "danger",
                                title: response.data.data,
                                text: "Por favor intente de nuevo con otro dedo",
                                icon: "ALertTriangleIcon",
                            },
                        });
                        this.dedo_samples = [];
                        this.dedo_seleccionado = null;
                        this.capturando = false;
                    }
                });
        },
    },
};
</script>

<style></style>
